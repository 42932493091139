.verification__form {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #cdd6fc;
}
.verification__title {
  text-align: center;
  font-size: 20px;
}
.verification__name {
  text-align: center;
  font-size: 28px;
}
.verification__helper-text {
  margin-bottom: 40px;
}
