@import "../../assets/css/variables";

.pages {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	@media (max-width: 1200px) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media (max-width: 767px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.pages__item-image {
  width: 100%;
  padding-top: 50%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
  border: 1px solid #CDD6FC;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: rgb(50 53 73 / 90%);
    opacity: 0;
  }
  .pages__item:hover &:after {
    opacity: 1;
  }
  .pages__item.fetching &:after {
    opacity: 1;
  }
}

.pages__item-center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  .pages__item:hover &,
  .pages__item.fetching & {
    opacity: 1;
    visibility: visible;
  }
  .pages__item.fetching & button {
    display: none;
  }
}

.pages__item-name {
  padding: 10px;
  border-radius: 0 0 8px 8px;
  border: 1px solid #CDD6FC;
  border-top: none;
  input {
    width: 100%;
    text-align: left;
    border: 1px solid transparent;
		font-weight: 600;
		font-size: 16px;
    outline: none;
		&:hover {
			border: 1px solid #CDD6FC;
		}
  }
}

.pages__item-status {
  display: none;
  color: #fff;
  .pages__item.fetching & {
    display: block;
  }
}

.pages__item-tools {
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  .pages__item:hover & {
    opacity: 1;
    visibility: visible;
  }
  path {
    fill: #fff;
  }
}