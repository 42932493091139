@import '../../assets/css/variables';

.profile {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .profile-sidebar {
    padding: 15px 0px;
    align-self: flex-start;
    width: 100%;
    max-width: 200px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid $c-border-light;
    margin-bottom: 30px;
    @media (min-width: 1200px) {
      position: fixed;
    }
    .profile-sidebar__inner {
      display: flex;
      flex-direction: column;
      a {
        padding: 15px 30px;
        border-left: 4px solid transparent;
        transition: width 0.3s, right 0.3s, background-color 0.3s;
      }
      a.profile-sidebar__nav-link.active {
        border-color: $c-bl;
        color: $c-bl;
      }
    }
  }
  .profile-content {
    @media (min-width: 1200px) {
      width: 70%;
      margin-left: 30%;
    }
    .profile-content__inner {
      margin-bottom: 50px;
    }
    .profile-content__inner,
    .profile-password__inner,
    .profile-avatar__inner {
      padding: 17px 30px 30px;
      border: 1px solid $c-border-light;
      background-color: #fff;
      border-radius: 8px;
    }
  }
}
.profile-update__avatar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 20px;
    object-fit: cover;
    border: 1px solid #dedede;
  }
  label {
    background-color: #3364e3;
    color: #fff;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  label:hover {
    background-color: #264fba;
  }
}
.avatar-user-profile {
  border-radius: 50%;
}
.profile-upload-helper.hidden {
  display: none;
}
.profile-update__avatar--button {
  position: relative;
  width: 100%;
}
.profile-upload-helper {
  position: absolute;
  width: 100%;
}
