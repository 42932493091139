.fogot-password__helper-text {
  text-align: center;
  margin-bottom: 50px;
}
.login__back-to-home-button {
  text-align: right;
  margin-bottom: 50px;
  color: #3c6df1;
}
.login__back-to-home-button span {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.login__back-to-home-button span svg {
  margin-right: 7px;
  font-size: 16px;
}
.lost-password-note {
  text-align: center;
  margin-bottom: 50px;
}
.chage-password__title {
  font-size: 38px;
}
