@import "variables";
@import "fonts";
@import "typography";

html, body {
	margin: 0;
}

body {
	* {
	    box-sizing: border-box;
	}
	font-family: Inter;
	font-size: 14px;
	line-height: 1.4;
	.MuiButton-contained:not(.Mui-disabled) {
		background-color: $c-bl !important;
		&:hover {
			background-color: $c-bl-dark !important;
		}
	}
	.MuiButton-outlined {
		color: $c-bl !important;
		border-color: $c-border-light !important;
	}
	.MuiDialogTitle-root {
		padding: 16px 16px 10px !important;
	}
	.MuiListItem-root {
		font-size: 14px !important;
		border-radius: 3px;
		&:hover {
			background: $c-bg-editor-1 !important;
		}
	}
	.MuiAlert-filledSuccess {
		background-color: $c-bl;
	}
}

.notice {
	margin-bottom: 40px;
	text-align: center;
	a {
	  color: $c-bl;
	}
}

h2 {
	font-size: 24px;
	margin: 10px 0 30px;
}

.main {
	background: $c-bg;
	min-height: 100vh;
	padding-left: 260px;
}

a {
	color: inherit;
	text-decoration: none;
}

.MuiDataGrid-footerContainer {
	border-top: none !important;
}

.MuiDataGrid-root {
	border: none !important;
}

.MuiDataGrid-cell:first-child,
.MuiDataGrid-columnHeader:first-child {
	padding-left: 30px !important;
}

.MuiDataGrid-cell:last-child,
.MuiDataGrid-columnHeader:last-child {
	padding-right: 30px !important;
}

.MuiFormControl-root,
.MuiFormGroup-root {
	margin-bottom: 15px !important;
}

.MuiButton-text {
	padding: 6px 20px !important;
}

.MuiButton-root {
	text-transform: none !important;
	box-shadow: none !important;
	height: 43px;
	font-size: 14px !important;
}

.MuiInputBase-input {
	font-size: 14px !important;
	&::placeholder {
		font-size: 14px !important;
	}
}

.MuiInputLabel-root {
	font-size: 14px !important;
}

.body-dashboard .MuiOutlinedInput-notchedOutline {
	border-color: $c-border-light !important;
}

.MuiSnackbarContent-root {
	justify-content: center;
	background: $c-bl !important;
	color: #fff !important;
}

.body-editor .MuiSnackbarContent-root {
	justify-content: center;
	background: $c-bg-editor-1 !important;
	color: #fff !important;
}

.flex {
	display: flex;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-end {
	justify-content: flex-end;
}

.flex-center {
	justify-content: center;
}

.flex-space-between {
	justify-content: space-between;
}

.align-center {
	align-items: center;
}

.margin-bottom-10 {
	margin-bottom: 10px;
}

.margin-bottom-20 {
	margin-bottom: 20px;
}

h1, h2, h3, h4 {
    font-weight: 700;
}

h1 {
    font-size: 40px;
    margin-bottom: 20px;
}

.editor {
	display: flex;
	color: #c5c5c5;
}

.hidden {
	display: none;
}

.hidden-mobile {
	display: block;
	@media (max-width: 768px) {
		display: none;
	}
}

.copy {
	padding: 5px 10px;
	border-radius: 3px;
	background-color: $c-bg-editor-2;
	cursor: pointer;
	font-size: 12px;
}

.editor-right {
	height: 100vh;
	width: calc(100% - 320px);
	transition: all ease .3s;
	position: relative;
	left: 0;
	.editor--full & {
		width: 100%;
		left: -320px;
	}
}

.editor-iframe {
	display: block;
	width: 100%;
	border: none;
	height: 100vh;
	margin: 0 auto;
	transition: all ease .3s;
	background-color: #fff;
	&.editor-iframe--mobile {
		width: 390px;
	}
	&.editor-iframe--tablet {
		width: 768px;
	}
	.editor--full & {
        height: 100vh;
    }
}

.editor-loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $c-bg-editor-2;
	z-index: 99999999;
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}

.editor__export {
	position: fixed;
	z-index: 9999999;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	background: $c-bg-editor-1;
	border-radius: 8px;
	padding: 20px;
	color: #fff;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	max-width: 400px;
}

.editor__export-control {
	margin-bottom: 10px;
	&:last-child {
		margin-bottom: 0;
	}
}

.editor__export-control-title {
	margin-bottom: 10px;
	font-weight: 700;
}

.editor__export-buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.editor__export-button {
	padding: 5px 10px;
	border-radius: 3px;
	background-color: $c-bg-editor-3;
	cursor: pointer;
	font-size: 12px;
	border: solid 1px transparent;
	&:hover {
		border: solid 1px $c-gray-border;
	}
}

.animation {
	margin: 0 0 6px;
	background: $c-bg-editor-1;
	border-radius: 8px;
	position: relative;
	height: 29px;
	color: #fff;
}

.animation__thumb {
	width: 100%;
	height: 29px;
	padding: 0 15px;
	display: flex;
	align-items: center;
	background: #454c4e;
	border-radius: 8px;
	font-size: 12px;
	position: relative;
	cursor: move;
	user-select: none;
	.animation.active & {
		background: #486491;
	}
}

.animation__thumb:before,
.animation__thumb:after {
	content: '';
	display: block;
	position: absolute;
	width: 8px;
	height: 8px;
	background: #656f72;
	.animation.active & {
		background: #5598ff;
	}
}

.animation__thumb:before {
	left: -4px;
	top: 50%;
	transform: translateY(-50%) rotate(45deg);
}

.animation__thumb:after {
	right: -4px;
	top: 50%;
	transform: translateY(-50%) rotate(45deg);
}

.animation__thumb-inner {
	overflow: hidden;
	white-space: nowrap;
}

.header {
	position: fixed;
	z-index: 999;
	width: 100%;
	top: 0;
	left: 0;
	background: #fff;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	border-bottom: 1px solid $c-border-light;
	.logo {
		@media (max-width: 767px) {
			width: 30px !important;
			overflow: hidden;
			svg {
				width: 130px !important;
			}
		}
	}
}

.header__inner {
	height: 70px;
	padding: 0 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.header__project {
	position: fixed;
	top: 71px;
	left: 0;
	z-index: 999;
	width: 100%;
	border-bottom: 1px solid $c-border-light;
	background-color: #F3F3F8;
}

.header__project-inner {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 10px 40px;
	gap: 20px;
}

.header__project-notice {
	a {
		color: $c-bl;
	}
	img {
		width: 20px;
		vertical-align: middle;
		margin-right: 10px;
	}
}

.header__button {
	height: 36px;
}

.user-tools {
	display: flex;
	align-items: center;
	gap: 10px;
}

.dashboard {
	min-height: 100vh;
	padding-top: 70px;
	background-color: $c-bg;
	&.dashboard--project {
		padding-top: 133px;
		@media (max-width: 767px) {
			padding-top: 240px;
		}
	}
}

.dashboard__content {
	padding: 40px;
}

.dashboard__content__inner {
	max-width: 1200px;
	margin: 0 auto;
}

.login__form {
	max-width: 500px;
	margin: 0 auto;
	padding: 30px;
	border-radius: 8px;
	background-color: #fff;
	border: 1px solid $c-border-light;
}

.login__title {
	text-align: center;
}

.login__button--google {
	img {
		position: absolute;
		left: 1px;
		background-color: #fff;
		padding: 7px;
		border-radius: 3px;
	}
}

.login__error {
    display: flex;
	margin-bottom: 15px;
    background-color: rgba(255, 79, 109, 0.15);
    padding: 10px 14px;
    border-radius: 4px;
	svg {
		fill: rgb(255, 79, 109);
		margin-right: 10px;
		min-width: 20px;
	}
}

.MuiDialog-paper {
    width: 400px;
	background-image: none !important;
	padding: 20px;
	font-size: 14px;
	border-radius: 10px !important;
}

.body-editor .MuiDialog-paper {
    width: 400px;
	background-color: $c-bg-editor-2 !important;
	background-image: none !important;
	padding: 20px;
	font-size: 14px;
	border-radius: 10px !important;
	color: $c-body !important;
}

.MuiDialogContent-root {
	border: none !important;
}

.body-editor .MuiDialogTitle-root {
	display: flex;
	align-items: center;
	font-size: 18px !important;
	color: #fff;
	margin-bottom: 5px !important;
}

.dialog__content {
	padding: 10px;
}
.projects,
.pages {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	@media (max-width: 1200px) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media (max-width: 767px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.projects__item {
	background-color: #fff;
	border-radius: 8px;
	border: 1px solid #CDD6FC;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	position: relative;
}

.projects__item-inner {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	min-height: 140px;
	padding: 20px;
	pointer-events: none;
	row-gap: 10px;
	* {
		pointer-events: all;
	}
}

.projects__item-name,
.pages__item-name,
.projects__item-url {
	
	input {
		width: 100%;
		border: 1px solid transparent;
		font-weight: 600;
		font-size: 18px;
		text-align: center;
		outline: none;
		&:hover {
			border: 1px solid #CDD6FC;
		}
	}
}

.projects__item-description {
	font-size: 14px;
}

.projects__item-url {
	width: 100%;
	padding: 10px;
	border-radius: 0 0 8px 8px;
	border-top: 1px solid #CDD6FC;
	pointer-events: none;
	font-size: 14px;
	input {
		text-align: left;
	}
}

.projects__item-tools,
.pages__item-tools {
	display: flex;
	align-items: center;
	gap: 10px;
	position: absolute;
	top: 10px;
	right: 10px;
}

.back-icon {
	background-color: #fff !important;
	border: 1px solid #CDD6FC !important;
}

.form-page {
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 767px) {
		flex-wrap: wrap;
		margin: 10px 0;
	}
	.MuiFormControl-root {
		margin-bottom: 0 !important;
	}
	.MuiButton-root {
		border-radius: 0 3px 3px 0 !important;
	}
	input {
		height: 43px !important;
		min-width: 300px;
		padding: 4px 10px !important;
		border-radius: 3px 0 0 3px !important;
		border: 1px solid #CDD6FC !important;
		outline: none;
		@media (max-width: 767px) {
			width: 100%;
		}
	}
}

.MuiFormControlLabel-label {
	font-size: 12px !important;
}

.editor-icon {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	background-color: #393939;
	border-radius: 3px;
	cursor: pointer;
}

.export-button.MuiButton-contained:not(.Mui-disabled) {
	width: 100%;
	padding: 2px;
	background-color: $c-bg-editor-1 !important;
	color: $c-body;
	&:hover {
		background-color: $c-bg-editor-3 !important;
	}
}

.dialog__footer-buttons {
	padding: 15px;
	display: flex;
	gap: 10px;
	button {
		width: 50%;
	}
}

.button-gray.MuiButton-contained:not(.Mui-disabled) {
	background-color: $c-bg-editor-1 !important;
	&:hover {
		background-color: $c-bg-editor-3 !important;
	}
}

.dialog__footer-import {
	margin-top: 15px;
	padding: 0 15px;
}

.dialog__footer-textarea {
	background-color: $c-bg-editor-3;
	width: 100%;
	border-radius: 8px;
	padding: 15px;
	border: none;
	color: #fff;
	outline: none;
	resize: none;
	
	/* width */
	&::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: $c-bg-editor-1;
		border-radius: 0 5px 5px 0;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #555;
		border-radius: 5px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.list-limited {
	max-height: 160px;
    overflow-y: scroll;

	/* width */
	&::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: $c-bg-editor-1;
		border-radius: 0 5px 5px 0;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #555;
		border-radius: 5px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.timeline-item {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.MuiListItem-root {
	padding: 11px 16px !important;
	position: relative;
	&:after {
		content: '';
		display: block;
		width: calc(100% - 30px);
		height: 1px;
		background-color: $c-bg-editor-1;
		position: absolute;
		left: 15px;
		top: 0;
	}
	&:first-child:after {
		display: none;
	}
	&:hover + .MuiListItem-root:after {
		display: none;
	}
}

.timeline-item__icon {
	display: flex;
	align-items: center;
	justify-content: center;
    font-size: 16px;
    line-height: 0;
	width: 30px;
	height: 30px;
	position: absolute;
	right: 16px;
	border-radius: 3px;
	cursor: pointer;
	&:hover {
		background-color: $c-bg-editor-2;
	}
}

.popover-list {
	padding: 5px 0;
	background-color: $c-bg-editor-1;
	font-size: 13px;
	color: $c-body;
}

.popover-list__item,
.context-menu__item {
	padding: 5px 10px;
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	&:hover {
		background-color: $c-bg-editor-2;
	}
}

.context-menu {
	position: fixed;
	padding: 5px 0;
	background-color: $c-bg-editor-1;
	font-size: 13px;
	color: $c-body;
	border-radius: 3px;
	box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.body-editor {
	.MuiPopover-paper {
		background-color: $c-bg-editor-2 !important;
		background-image: none !important;
	}
	.MuiPopover-root {
		z-index: 9999999;
	}
}

.Mui-selected {
	background-color: $c-bg-editor-1 !important;
}

.litemove-invisible {
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
}

.editor-iframe-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
}

.scrollTrigger-markers {
	position: absolute;
	width: 100%;
	pointer-events: none;
	z-index: 9;
	text-align: right;
	font-size: 12px;
	padding: 10px 30px;
	
	&.scrollTrigger-markers--end {
		color: red;
	}
	
	&.scrollTrigger-markers--start {
		color: green;
	}
}

@keyframes moveUpDown {
	0%, 100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-6px);
	}
}

.steps__item {
	margin-bottom: 20px;
}

.steps__item-title {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 700;
}

.steps__item-title-number {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $c-bl;
	color: #fff;
	border-radius: 3px;
}

.steps__item-title-text {
	margin-left: 10px;
}

.steps__item-desc {
	padding-left: 40px;
	a {
		color: $c-bl;
	}
}

.require-notices {
	display: block;
	border: solid 2px $c-bl;
	border-radius: 8px;
	padding: 10px 10px 0 10px;
	margin-bottom: 20px;
	background-color: $c-bg-editor-1;
	a:hover {
		text-decoration: underline;
	}
	ul {
		margin: 7px 0 10px 0;
		padding-left: 10px;
		list-style: none;
		li {
			margin-bottom: 5px;
			&:last-child {
				margin-bottom: 0;
			}
			&::before {
				content: '-';
				display: inline-block;
				width: 1em;
				margin-left: -1em;
			}
		}
	}
}